import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import "../../../css/custom-css/custom-products-services.css"

import Layout from "../../../components/custom-components/Layout"
import BannerHeader from "../../../components/custom-components/BannerHeader"

// import bg from "../../../images/products-and-services/header-cmo.webp"
import bg from "../../../images/products-and-services/header-other.jpg"
import Seo from "../../../components/seo"

export const query = graphql`
  {
    image1: contentfulServices(title: { eq: "other-1" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
    image2: contentfulServices(title: { eq: "other-2" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
    image3: contentfulServices(title: { eq: "other-3" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
  }
`

const OtherServices = () => {
  const data = useStaticQuery(query)
  const image1 = data.image1
  const image2 = data.image2
  const image3 = data.image3
  return (
    <Layout>
      <Seo title="Other Services" />

      <BannerHeader background={bg} title="Other Services" servicesDetails />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div>
              <Link href="/products-and-services">
                <div className="icon-content">
                  <h5 className="dlab-title">
                    <span className="icon-sm">
                      <i className="ti-arrow-left"></i>
                    </span>
                    Back
                  </h5>
                </div>
              </Link>
            </div>

            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/products-and-services/services/novel-diagnostics">
                        Novel Diagnostics
                      </Link>
                    </li>
                    <li>
                      <Link to="/products-and-services/services/therapeutics">
                        Therapeutics
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/products-and-services/services/other-services">
                        Other Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                {/* <div className="row">
                  <div className="col-lg-6 col-md-12 m-b30">
                    <div className="dlab-box">
                      <div className="dlab-media">
                        <GatsbyImage image={image1.image.gatsbyImageData} />
                      </div>
                      <div className="dlab-info m-t30 ">
                        <h4 className="dlab-title m-t0">
                          <Link to="#">Other Services Subheading 1 </Link>
                        </h4>
                        <p className="text-justify">
                          We welcome collaborations in clinical research and
                          have several ongoing clinical trials.
                        </p>
                        <p className="text-justify">
                          Our biosimilar monoclonal antibody, Rituximab, is
                          currently in phase III trial under a collaboration
                          with the licenser who is undertaking the trial in
                          South America.
                        </p>
                        <p className="text-justify">
                          Innogene Kalbiotech has maintained oversight of
                          Indonesia and The Philippines since 2013 for the
                          Diffused Large B-cell Lymphoma indication. We also
                          conducted a phase II trial of the humanized monoclonal
                          antibody for the treatment of cervical cancer in
                          Indonesia. The results of these trials will be
                          published by the end of 2014.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dlab-box">
                      <div className="dlab-media m-b30 p-b5">
                        <GatsbyImage image={image2.image.gatsbyImageData} />
                      </div>
                      <div className="dlab-media">
                        <GatsbyImage image={image3.image.gatsbyImageData} />
                      </div>
                      <div className="dlab-info m-t30">
                        <h4 className="dlab-title m-t0">
                          <Link to="#">Other Services Subheading 2</Link>
                        </h4>
                        <p className="text-justify">
                          There are two ongoing trials for our humanized
                          monoclonal antibody for the treatment of head and neck
                          cancer. The phase II trial in non-operable head and
                          neck cancer is being conducted in Thailand and
                          Singapore. The phase III trial in operable head and
                          neck cancer is underway in Australia, South Korea,
                          India, Saudi Arabia, South Africa, Singapore,
                          Malaysia, Philippines, Thailand and Indonesia. We plan
                          to open additional sites for this trial in China and
                          Taiwan by 2015.
                        </p>
                        <p className="text-justify">
                          Innogene Kalbiotech and our sister companies also
                          provide laboratory services such as BA (Bioavailabity)
                          and BE (Bioequivalence) studies, QA (Quality
                          Assurance) and other diagnostic services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-4 col-sm-12 mb-4">
                    <GatsbyImage image={image1.image.gatsbyImageData} />
                  </div>
                  <div className="col-md-4 col-sm-12 mb-4">
                    <GatsbyImage image={image2.image.gatsbyImageData} />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <GatsbyImage image={image3.image.gatsbyImageData} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4 className="dlab-title mt-2">Other Services</h4>
                    <p className="text-justify">
                      We welcome collaborations in clinical development with
                      companies which have strong science-based pipeline. We
                      have successfully completed and have several on-going
                      clinical trials across ASEAN and Australia. Some of our
                      completed success stories are:
                      <ul className="ml-4">
                        <li>
                          Global phase 3 trial in diffuse large B-cell lymphoma
                          in Indonesia, Malaysia and Philippines completed in
                          2017 and published
                        </li>
                        <li>
                          Global phase 2 trial in inoperable squamous cell
                          carcinoma of head and neck in Singapore and Thailand,
                          completed in 2012 and published
                        </li>
                        <li>Phase 2 trial in Cervical cancer in Indonesia</li>
                        <li>
                          Global phase 3 trial in resected squamous cell
                          carcinoma of head and neck in Singapore, Australia,
                          Taiwan, S Korea, Saudia Arabia, Cuba, India,
                          Philippines, Malaysia, Indonesia, S Africa and
                          Thailand, in follow up and planned to complete in 2023
                        </li>
                      </ul>
                    </p>
                    <p className="text-justify mt-3">
                      We and our sister companies also provide laboratory
                      services such as BA (Bioavailability) and BE
                      (Bioequivalence) studies, CMO for biologics, and
                      diagnostic services including biomarker.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OtherServices
